

.chat-footer {
  border-top: 1px solid #4087FA;
}

.chat-wrapper {
  margin: 0 10px 30px 10px;
  display: flex;
  flex-direction: column;
  clear: both;
  position: relative;


  .chat-entry {
    padding: 10px 10px 20px 10px;
    color: #000;
    overflow: hidden;
    font-size: 0.9rem;
    min-height: 70px;
    display: flex;
    width: 100%;

    .time {
      color: dimgrey;
      margin-top: 10px
    }

    .message {
      padding: 10px;
      border-radius: 7.5px;
      background-color: gainsboro;
    }
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;
  }

  .chat-bubble {
    min-width: 10%;
    max-width: 65%;
  }

  .chat-bubble-right {
    text-align: right;

    .message {
      background-color: #4087FA;
      color: #fff;
    }
  }

}

.send-button {
  position: absolute;
  bottom: 0;
  right: 5px;
}

